import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/emcasa/ui/node_modules/gatsby-theme-docz/src/base/Layout.js";
import Button from '@emcasa/ui-dom/components/Button';
import Text from '@emcasa/ui-dom/components/Text';
import View from '@emcasa/ui-dom/components/View';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "button"
    }}>{`Button`}</h1>
    <p>{`Button component.`}</p>
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <View m={4} mdxType="View">
  <Button active mdxType="Button">Active</Button>
  <Button mdxType="Button">Normal</Button>
  <Button disabled mdxType="Button">Disabled</Button>
  <Button link mdxType="Button">Link</Button>
  <Button link disabled mdxType="Button">Disabled link</Button>
  <Button active disabled mdxType="Button">Active and disabled</Button>
  <View my={4} mdxType="View">
    <Button fluid active mdxType="Button">This Button takes up all available space</Button>
  </View>
    </View>
    <View m={4} mdxType="View">
  <Button height="tall" mdxType="Button">Tall</Button>
  <Button height="medium" mdxType="Button">Medium</Button>
  <Button height="short" mdxType="Button">Short</Button>
    </View>
    <h1 {...{
      "id": "buttongroup"
    }}>{`Button.Group`}</h1>
    <p>{`Selectable group of buttons`}</p>
    <Button.Group onChange={value => console.log('selected', value)}>
  <Button value="a" mdxType="Button">Button A</Button>
  <Button value="b" mdxType="Button">Button B</Button>
  <Button value="c" mdxType="Button">Button C</Button>
    </Button.Group>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      